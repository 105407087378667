
import Vue from 'vue';
import _isEqual from 'lodash.isequal';
import { mapMutations } from 'vuex';

import ProposalMarketMap from '@/components/Proposal/ProposalMarketMap.vue';
import TargetedAudienceSelector from '@/components/Proposal/TargetAudienceSelector.vue';
import { SegmentItem } from '@/injectables';
import { MutationTypes } from '../../store/newProposal/mutations';

export default Vue.extend({
  name: 'ProposalMarket',

  components: { ProposalMarketMap, TargetedAudienceSelector },

  props: {
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data: (): {
    offsetForDemographics: number;
    mapHeight: number;
    demoField: string;
    demoFields: string[];
    initialMapState: {
      states: string[];
      dmas: string[];
      counties: string[];
      cities: string[];
      zips: string[];
    } | null;
    isDemographicsUpdated: boolean;
  } => ({
    isDemographicsUpdated: false,
    initialMapState: null,
    offsetForDemographics: 400,
    mapHeight: 0,
    demoField: 'Demographics',
    demoFields: ['Demographics', 'Enthusiasts', 'Lifestyle', 'Occupation', 'Search History', 'Shopping'],
  }),

  watch: {
    selectedStates(newArr, oldArr): void {
      if (newArr?.length === oldArr?.length && newArr.every((el, i) => el === oldArr[i])) {
        return;
      }
      this.getCensus();
    },
    selectedCities(newArr, oldArr): void {
      if (newArr?.length === oldArr?.length && newArr.every((el, i) => el === oldArr[i])) {
        return;
      }
      this.getCensus();
    },
    selectedCounties(newArr, oldArr): void {
      if (newArr?.length === oldArr?.length && newArr.every((el, i) => el === oldArr[i])) {
        return;
      }
      this.getCensus();
    },
    selectedZips(newArr, oldArr): void {
      if (newArr?.length === oldArr?.length && newArr.every((el, i) => el === oldArr[i])) {
        return;
      }
      this.getCensus();
    },
    selectedDmas(newArr, oldArr): void {
      if (newArr?.length === oldArr?.length && newArr.every((el, i) => el === oldArr[i])) {
        return;
      }
      this.getCensus();
    },
  },

  computed: {
    proposalInState() {
      return this.$store.state.newProposal.newProposal?.id;
    },
    demographics: {
      get(): SegmentItem[] {
        return this.$store.state.newProposal.newProposal.demographics || [];
      },
      set(val: SegmentItem[]) {
        this.$store.dispatch('newProposal/updateMarketDemographics', val);
        this.isDemographicsUpdated = true;
        this.$store.dispatch('newProposal/setIsProposalMarketUpdated', true);
      },
    },
    audience: {
      get(): SegmentItem[] {
        return this.$store.state.newProposal.newProposal.audience || [];
      },
      set(val: SegmentItem[]) {
        this.$store.dispatch('newProposal/updateMarketAudience', val);
        this.isDemographicsUpdated = true;
        this.$store.dispatch('newProposal/setIsProposalMarketUpdated', true);
      },
    },
    isProposalMarketUpdated(): boolean {
      return this.$store.getters['newProposal/isProposalMarketUpdated'];
    },
    selectedStates(): string[] {
      return this.$store.state['newProposal'].newProposal.market.geoSelections?.stateList || [];
    },
    selectedCities(): string[] {
      return this.$store.state['newProposal'].newProposal.market.geoSelections?.cityList || [];
    },
    selectedZips(): string[] {
      return this.$store.state['newProposal'].newProposal.market.geoSelections?.zipList || [];
    },
    selectedCounties(): string[] {
      return this.$store.state['newProposal'].newProposal.market.geoSelections?.countyList || [];
    },
    selectedDmas(): string[] {
      return this.$store.state['newProposal'].newProposal.market.geoSelections?.dmaList || [];
    },
  },

  methods: {
    ...mapMutations('newProposal', { setProposalMarket: MutationTypes.SET_PROPOSAL_MARKET }),
    cleanGeoSelections(): void {
      this.setProposalMarket({
        market: {
          ...this.$store.state['newProposal'].newProposal.market,
          geoSelections: {
            stateList: [],
            cityList: [],
            zipList: [],
            countyList: [],
            dmaList: [],
          },
        },
      });
    },
    onMapUpdate(): void {
      const currentMapState = {
        states: [...this.selectedStates],
        dmas: [...this.selectedDmas],
        counties: [...this.selectedCounties],
        cities: [...this.selectedCities],
        zips: [...this.selectedZips],
      };

      if (_isEqual(currentMapState, this.initialMapState) && !this.isDemographicsUpdated) {
        this.$store.dispatch('newProposal/setIsProposalMarketUpdated', false);
      } else {
        this.$store.dispatch('newProposal/setIsProposalMarketUpdated', true);
      }
    },
    saveProgress(): void {
      this.$store
        .dispatch('newProposal/setMarketData', {})
        .then(() => this.$store.dispatch('newProposal/setIsProposalMarketUpdated', false))
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error('proposalMarket', 'saveProgress', err);
          this.$store.dispatch('showSnackbar', {
            content: 'Unable to save market changes at this time. Please refresh.',
            color: 'warning',
          });
        });
    },
    getCensus(): void {
      if (
        !this.selectedZips.length &&
        !this.selectedCities.length &&
        !this.selectedDmas.length &&
        !this.selectedStates.length &&
        !this.selectedCounties.length
      ) {
        return;
      }
      const selectionsForCensus = {
        ...(this.selectedStates?.length ? { states: this.selectedStates } : null),
        ...(this.selectedDmas?.length ? { dmas: this.selectedDmas } : null),
        ...(this.selectedZips?.length ? { zips: this.selectedZips } : null),
        ...(this.selectedCities?.length ? { cities: this.selectedCities } : null),
        ...(this.selectedCounties?.length ? { counties: this.selectedCounties } : null),
      };

      this.$store.dispatch('newProposal/getCensus', selectionsForCensus);
    },
    onWindowResize(): void {
      setTimeout(this.adjustMap, 100);
    },
    adjustMap(): void {
      this.mapHeight = this.$refs?.marketMapContainer.clientHeight || 400;
    },
  },

  mounted(): void {
    this.$store.dispatch('targetingSegments/getTargeting');

    this.initialMapState = {
      states: [...this.selectedStates],
      dmas: [...this.selectedDmas],
      counties: [...this.selectedCounties],
      cities: [...this.selectedCities],
      zips: [...this.selectedZips],
    };
    this.adjustMap();
    window.addEventListener('optimizedResize', this.onWindowResize);
    this.getCensus();
  },

  beforeDestroy() {
    window.removeEventListener('optimizedResize', this.onWindowResize);
  },

  async beforeRouteLeave(to, from, next): Promise<void> {
    if (this.isProposalMarketUpdated) {
      await this.saveProgress();
    }
    this.$store.dispatch('output/resetOutputAndProposal', {
      routeName: to.name,
    });
    next();
  },
});
